<template>
  <div class="view-document" v-if="document">
      <label @click="$router.go(-1)" class="back">
        <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </figure>
        <p>Voltar</p>
      </label>
      <div class="infos" v-if="document">
        <div class="interactions">
          <div class="stats" :class="{'reopen': abilities.reopenDocument, 'close': isDocumentOpenValue && abilities.completeDocument, 'block': !(isDocumentOpenValue && abilities.completeDocument && abilities.reopenDocument && !abilities.transferDocument)}">
            <div class="tag">{{ document.number }}</div>
            <!-- TAGS CLASSES processing, concluded, late, extended, pending, exceptions -->
            <div class="tag processing" :class="document.status">{{ documentStatusTranslations[document.status] }}</div>
            <div v-if="document.is_exception" class="tag exceptions">Exceções</div>
          </div>
          <h1>Documento</h1>
          <h2 class="subtitle">{{ document.title }}</h2>
          <div class="document" v-for="interaction in document.interactions">
            <h4 class="interaction-creator">{{ interaction.creator.name }}</h4>
            <div class="document__item">
                <div v-html="interaction.content"></div>
            </div>
            <div v-if="interaction.files.length" class="document__attachments">
              <h1>DOCUMENTOS EM ANEXO</h1>
              <div v-for="file in interaction.files">
                <p>{{ file.name }}</p>
                <div>
                  <button v-if="file.mime_type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" @click="visualizeFile(file)" class="tooltip">
                    <figure>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.5819 11.9999C15.5819 13.9799 13.9819 15.5799 12.0019 15.5799C10.0219 15.5799 8.42188 13.9799 8.42188 11.9999C8.42188 10.0199 10.0219 8.41992 12.0019 8.41992C13.9819 8.41992 15.5819 10.0199 15.5819 11.9999Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.9998 20.2702C15.5298 20.2702 18.8198 18.1902 21.1098 14.5902C22.0098 13.1802 22.0098 10.8102 21.1098 9.40021C18.8198 5.80021 15.5298 3.72021 11.9998 3.72021C8.46984 3.72021 5.17984 5.80021 2.88984 9.40021C1.98984 10.8102 1.98984 13.1802 2.88984 14.5902C5.17984 18.1902 8.46984 20.2702 11.9998 20.2702Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </figure>
                  </button>
                  <a :href="file.path.includes('data:') ? file.path : `data:${file.mime_type};base64,${file.path}`" :download="file.name" :type="file.mime_type">
                    <button class="tooltip">
                      <figure>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_519_4148)">
                            <path d="M7.5 9.1665V14.1665L9.16667 12.4998"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.50065 14.1667L5.83398 12.5"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18.3327 8.33317V12.4998C18.3327 16.6665 16.666 18.3332 12.4993 18.3332H7.49935C3.33268 18.3332 1.66602 16.6665 1.66602 12.4998V7.49984C1.66602 3.33317 3.33268 1.6665 7.49935 1.6665H11.666"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18.3327 8.33317H14.9993C12.4993 8.33317 11.666 7.49984 11.666 4.99984V1.6665L18.3327 8.33317Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_519_4148">
                              <rect width="20" height="20" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </figure>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <TextEditor v-if="isDocumentOpenValue" :setValue="(value) => updatePayload.content = value" />
          <h1 v-if="keywords.length">Palavras chaves</h1>
          <h2 v-if="keywords.length">Separe por vírgula, exemplo: férias, pagamento.</h2>
          <MyTags v-if="keywords.length" :value="keywords" :name="'keywords'" :placeholder="'Digite a palavra chave'" :disabled="true" />
          <h1 v-if="isDocumentOpenValue">Arquivo do documento</h1>
          <h2 v-if="isDocumentOpenValue">Adicione um arquivo a esse documento, podendo ser DOC ou PDF.</h2>
          <FileUploader v-if="isDocumentOpenValue" :files="updatePayload.files!" :pushFile="(file) => updatePayload.files!.push(file)" />
          <h1 v-if="mentions.length">Citado(s) no(s) documento(s)</h1>
          <h2 v-if="mentions.length">Escreva nome e sobrenome dos participantes citados no(s) anexo(s) e separe por vírgula.</h2>
          <MyTags v-if="mentions.length" :value="mentions" :name="'mentions'" :placeholder="'Digite nome e sobrenome'" :disabled="true" />
          <h1 v-if="isDocumentOpenValue">Setor <strong>(obrigatório)</strong></h1>
          <h2 v-if="isDocumentOpenValue">Qual setor agora precisa ter acesso a esse documento?</h2>
          <MyOptions v-if="isDocumentOpenValue" :currentValue="updatePayload.organization?.name ?? 'Selecione'">
            <div class="item" v-for="(organization, index) in organizations" :key="index">
              <input type="radio" :id="'organizations-' + index" name="organizations" :value="organization" v-model="updatePayload.organization" @change="($event.target as HTMLInputElement).checked ? (updatePayload.responsible = undefined) : ''">
              <label :for="'organizations-' + index">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                    <g clip-path="url(#clip0_388_3128)">
                      <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_388_3128">
                        <rect width="12.5" height="10" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <p>{{organization.name}}</p>
              </label>
            </div>
          </MyOptions>
          <h1 v-if="isDocumentOpenValue">Responsável <strong>(obrigatório)</strong></h1>
          <h2 v-if="isDocumentOpenValue">Qual responsável agora precisa ter acesso a esse documento?</h2>
          <MyOptions v-if="isDocumentOpenValue" :currentValue="updatePayload?.responsible?.name ? `${updatePayload.responsible.name} ${updatePayload.responsible.last_name ?? ''}` : 'Selecione'">
            <div class="item" v-for="(responsible, index) in usersToTransfer" :key="index">
              <input type="radio" :id="'responsible-' + index" name="responsible" :checked="updatePayload.responsible?.id == responsible.id" :value="responsible" v-model="updatePayload.responsible"
              @change="($event.target as HTMLInputElement).checked ? (updatePayload.organization = updatePayload.responsible?.organization) : ''">
              <label :for="'responsible-' + index">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                    <g clip-path="url(#clip0_388_3128)">
                      <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_388_3128">
                        <rect width="12.5" height="10" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <p>{{ responsible.name }} {{ responsible.last_name }}</p>
              </label>
            </div>
          </MyOptions>
          <h1 v-if="abilities.extendDocument">Data limite <strong>(obrigatório)</strong></h1>
          <h2 v-if="abilities.extendDocument">Prazo máximo para o documento ser apreciado</h2>
          <label v-if="abilities.extendDocument" class="date-picker-label" for="date-limit-document" @click="isDatePickerModalOpen = true;">
          <!-- :value="limitDate.replaceAll('-', '/')" -->
            <input type="text" placeholder="Selecione uma data" name="date-limit-document" id="date-limit-document" :value="updatePayload.limit_date ? formatDate(updatePayload.limit_date) : formatDate(document.limit_date.slice(0, 10))" readonly>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.5 9.08984H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.6937 13.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.6937 16.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.9945 13.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.9945 16.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.29529 13.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.29529 16.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </label>
          <MyModal v-if="abilities.extendDocument" :width="'341px !important'" :height="'517px !important'" :className="'date-limit'" :isOpen="isDatePickerModalOpen" :toggleModal="(v: boolean) => isDatePickerModalOpen = v" :zIndex="100">
            <div class="modal-date-title">
              <h1>Data limite</h1>
              <h2>Prazo máximo para ser aceito.</h2>
            </div>
            <MyDatePicker :min="{ day: new Date(document.limit_date).getDate(), month: new Date(document.limit_date).getMonth() + 1, year: new Date(document.limit_date).getFullYear() }" :name="'limit_date'" :setValue="(date) => limitDateValue = date"/>
            <input type="button" value="Aplicar data" @click="updatePayload.limit_date = limitDateValue; isDatePickerModalOpen = false">
          </MyModal>
          <MyModal v-if="isDocumentOpenValue && abilities.transferDocument" :width="'776px'" :height="'1171px'" :isOpen="isTransferDocumentModalOpen" :class-name="'transfer-document'" :toggleModal="(v: boolean) => isTransferDocumentModalOpen = v" :zIndex="100">
            <div class="transfer">
              <h1>Transferir documento</h1>
              <h1>Setores <strong>(obrigatório)</strong></h1>
              <h2>Qual setores agora precisa ter acesso a esse documento?</h2>
              <MyOptions :currentValue="updatePayload.organization?.name ?? 'Selecione'">
                <div class="item" v-for="(organization, index) in organizations" :key="index">
                  <input type="radio" :id="'organizations-transfer-modal-' + index" name="organizations-transfer-modal" :value="organization" v-model="updatePayload.organization" @change="($event.target as HTMLInputElement).checked ? (updatePayload.responsible = undefined) : ''">
                  <label :for="'organizations-transfer-modal-' + index">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                        <g clip-path="url(#clip0_388_3128)">
                          <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_388_3128">
                            <rect width="12.5" height="10" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <p>{{organization.name}}</p>
                  </label>
                </div>
              </MyOptions>
              <h1>Responsável <strong>(obrigatório)</strong></h1>
              <h2>Qual responsável agora precisa ter acesso a esse documento?</h2>
              <MyOptions :currentValue="updatePayload?.responsible?.name ? `${updatePayload.responsible.name} ${updatePayload.responsible.last_name ?? ''}` : 'Selecione'">
                <div class="item" v-for="(responsible, index) in usersToTransfer" :key="index">
                  <input type="radio" :id="'responsible-transfer-modal-' + index" name="responsible-transfer-modal" :checked="updatePayload.responsible?.id == responsible.id" :value="responsible" v-model="updatePayload.responsible"
                  @change="($event.target as HTMLInputElement).checked ? (updatePayload.organization = updatePayload.responsible?.organization) : ''">
                  <label :for="'responsible-transfer-modal-' + index">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                        <g clip-path="url(#clip0_388_3128)">
                          <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_388_3128">
                            <rect width="12.5" height="10" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <p>{{ responsible.name }} {{ responsible.last_name }}</p>
                  </label>
                </div>
              </MyOptions>
              <h1>Conteúdo desse documento <strong>(obrigatório)</strong></h1>
              <h2>Descreva os principais detalhes que irão ajudar aos interessados saber sobre o documento.</h2>
              <TextEditor :setValue="(value) => updatePayload.content = value" />
              <h1>Arquivo do documento</h1>
              <h2>Adicione um arquivo a esse documento, podendo ser DOC ou PDF.</h2>
              <FileUploader :files="updatePayload.files!" :pushFile="(file) => updatePayload.files!.push(file)" />
            </div>
            <nav>
              <input :disabled="!updatePayload.responsible && !updatePayload.files?.length && !updatePayload.limit_date" type="button" value="Enviar" @click="updateDocument()" v-if="!isUpdatingDocument">
              <SpinnerLoaderButton v-else :height="60" :maxWidth="464"/>
              <input type="button" value="Cancelar" @click="isTransferDocumentModalOpen = false;">
            </nav>
          </MyModal>
          <section v-if="isDocumentOpenValue" class="actions-doc">
            <nav>
              <input v-if="!isUpdatingDocument" type="button" value="Enviar" @click="updateDocument()" :disabled="(!updatePayload.responsible && !updatePayload.limit_date) || (!isPayloadContentEmpty && !updatePayload.responsible)">
              <SpinnerLoaderButton v-else :height="60" :maxWidth="464"/>
              <input type="button" value="Cancelar" @click="$router.go(-1)">
            </nav>
          </section>
          <section v-else-if="abilities.reopenDocument" @click="isConfirmCompleteDocumentModal = true" class="actions-doc reopen">
            <nav>
              <button>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fill='none' d="M14.166 18.3335H5.83268C2.49935 18.3335 1.66602 17.5002 1.66602 14.1668V12.5002C1.66602 9.16683 2.49935 8.3335 5.83268 8.3335H14.166C17.4993 8.3335 18.3327 9.16683 18.3327 12.5002V14.1668C18.3327 17.5002 17.4993 18.3335 14.166 18.3335Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill='none' d="M5 8.33317V6.6665C5 3.90817 5.83333 1.6665 10 1.6665C13.75 1.6665 15 3.33317 15 5.83317" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill='none' d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Reabrir
              </button>
            </nav>
          </section>
        </div>
        <div class="historics">
          <div class="actions">
            <button v-if="isDocumentOpenValue && abilities.completeDocument" @click="isConfirmCompleteDocumentModal = true">
              <svg v-if="!isDocumentOpenValue" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill='none' d="M14.166 18.3335H5.83268C2.49935 18.3335 1.66602 17.5002 1.66602 14.1668V12.5002C1.66602 9.16683 2.49935 8.3335 5.83268 8.3335H14.166C17.4993 8.3335 18.3327 9.16683 18.3327 12.5002V14.1668C18.3327 17.5002 17.4993 18.3335 14.166 18.3335Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M5 8.33317V6.6665C5 3.90817 5.83333 1.6665 10 1.6665C13.75 1.6665 15 3.33317 15 5.83317" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                <g clip-path="url(#clip0_519_4047)">
                  <path stroke='none' d="M3.87391 8.53798C3.77705 8.63467 3.64578 8.68897 3.50891 8.68897C3.37205 8.68897 3.24078 8.63467 3.14391 8.53798L0.227247 5.62132C0.155209 5.54941 0.0980586 5.464 0.0590649 5.36999C0.0200711 5.27597 0 5.17518 0 5.0734C0 4.97162 0.0200711 4.87083 0.0590649 4.77681C0.0980586 4.68279 0.155209 4.59739 0.227247 4.52548L0.592247 4.16132C0.73758 4.01603 0.934665 3.93441 1.14016 3.93441C1.34566 3.93441 1.54275 4.01603 1.68808 4.16132L3.50891 5.98048L8.42558 1.06382C8.57091 0.91853 8.768 0.836914 8.9735 0.836914C9.17899 0.836914 9.37608 0.91853 9.52141 1.06382L9.88641 1.42882C9.95845 1.50072 10.0156 1.58613 10.0546 1.68015C10.0936 1.77416 10.1137 1.87495 10.1137 1.97673C10.1137 2.07852 10.0936 2.1793 10.0546 2.27332C10.0156 2.36734 9.95845 2.45274 9.88641 2.52465L3.87391 8.53798Z" />
                </g>
                <defs>
                  <clipPath id="clip0_519_4047">
                    <rect width="10.1175" height="7.85583" fill="white" transform="translate(0 0.833496)"/>
                  </clipPath>
                </defs>
              </svg>
              Concluir
            </button>
            <button v-else-if="abilities.reopenDocument" @click="isConfirmCompleteDocumentModal = true" id="reopen">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill='none' d="M14.166 18.3335H5.83268C2.49935 18.3335 1.66602 17.5002 1.66602 14.1668V12.5002C1.66602 9.16683 2.49935 8.3335 5.83268 8.3335H14.166C17.4993 8.3335 18.3327 9.16683 18.3327 12.5002V14.1668C18.3327 17.5002 17.4993 18.3335 14.166 18.3335Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M5 8.33317V6.6665C5 3.90817 5.83333 1.6665 10 1.6665C13.75 1.6665 15 3.33317 15 5.83317" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Reabrir
            </button>
            <button :disabled="!abilities.transferDocument" @click="isTransferDocumentModalOpen = true" v-if="isDocumentOpenValue">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill='none' d="M8.33268 4.99984H11.666C13.3327 4.99984 13.3327 4.1665 13.3327 3.33317C13.3327 1.6665 12.4993 1.6665 11.666 1.6665H8.33268C7.49935 1.6665 6.66602 1.6665 6.66602 3.33317C6.66602 4.99984 7.49935 4.99984 8.33268 4.99984Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M11.6667 18.3334H7.5C3.33333 18.3334 2.5 16.6668 2.5 13.3334V8.33343C2.5 4.53343 3.89167 3.5001 6.66667 3.3501" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M13.334 3.3501C16.109 3.5001 17.5007 4.5251 17.5007 8.33343V12.5001" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M17.5 15.8335V18.3335H15" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill='none' d="M12.5 13.3335L17.4667 18.3002" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Transferir
            </button>
          </div>
          <h1>Informações</h1>
          <div class="info">
            <p v-if="document.creator?.organization">Setor inicial: <strong>{{ document.creator?.organization?.acronym ? document.creator?.organization.acronym : document.creator?.organization?.name }}</strong></p>
            <p>Assunto: <strong>{{ document.subject.name }}</strong></p>
            <p>Criado por: <strong>{{ document.creator?.name }}</strong></p>
            <p v-if="document.responsibles">Responsável: <strong>{{ document.responsibles[0].name }}</strong><strong v-if="document.responsibles[0].last_name">{{ document.responsibles[0].last_name }}</strong></p>
            <div>
              <p>Criado em: <strong>{{ new Date(document.created_at).toLocaleDateString() }}</strong></p>
              <p>Data limite: <strong>{{ new Date(document.limit_date).toLocaleDateString() }}</strong></p>
            </div>
          </div>
          <h1>Histórico</h1>
          <div class="historic">
            <div class="item" v-for="(log, index) in document.logs.filter(log => log.action !== DocumentLogActionEnum.VIEW)">
              <span>{{ index + 1 }}</span>
              <p>{{ log.creator.name }}: <strong>{{ documentLogActionTranslations[log.action] }}</strong></p>
              <p>Log criado em: <strong>{{ new Date(log.created_at).toLocaleDateString() }} ás {{ new Date(log.created_at).toLocaleTimeString().slice(0, 5) }}</strong></p>
              <p v-if="log.limit_date">Vencimento: <strong>{{ new Date(log.limit_date).toLocaleDateString() }}</strong></p>
              <div>
                <p v-if="log.responsibles.length"><strong>{{ log.creator.name }}</strong> enviou o documento <strong>{{ document.number }}</strong> para <strong>{{ log.responsibles[0].name }}</strong> da(o) {{ log.responsibles[0].organization?.name }}.</p>
                <p v-else-if="log.action == 'extension'"><strong>{{ log.creator.name }}</strong> prorrogou a data limite do documento para <strong>{{ new Date(log.limit_date).toLocaleDateString('pt-BR') }}</strong></p>
                <p v-else-if="log.action == 'completion'"><strong>{{ log.creator.name }}</strong> finalizou o documento <strong>{{ document.number }}</strong></p>
                <p v-else-if="log.action == 'reopening'"><strong>{{ log.creator.name }}</strong> reabriu o documento <strong>{{ document.number }}</strong></p>
              </div>
            </div>
          </div>
          <h1 v-if="documentViews.length">Vizualizações</h1>
          <div class="viewers" v-if="documentViews.length">
            <div class="viewer" v-for="view in documentViews">
              <p>{{ view.creator.name }}<span v-if="view.creator.last_name"> {{ view.creator.last_name }}</span>: <strong>Visualizou o comunicado</strong></p>
              <p>Log criado em: <strong>{{ new Date(view.created_at).toLocaleDateString() }} ás {{ new Date(view.created_at).toLocaleTimeString().slice(0, 5) }}</strong></p>
            </div>
          </div>
        </div>
      </div>

      <MyModal v-if="abilities.completeDocument || abilities.reopenDocument" :width="!isDocumentOpenValue ? '330px !important' : '330px !important'" :height="!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime() ? '517px !important' : '299px !important'" :isOpen="isConfirmCompleteDocumentModal" :class-name="!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime() ? 'confirm-complete-document picker' : 'confirm-complete-document'" :toggleModal="(v: boolean) => isConfirmCompleteDocumentModal = v" :zIndex="100">
        <div>
          <svg v-if='isDocumentOpenValue || !(!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime())' :style="!(!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime()) && !isDocumentOpenValue ? 'margin: 0 auto;' : ''" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
            <path d="M33.7569 42.6831C29.1852 43.0081 29.1852 49.6381 33.7569 49.9631H44.6119C45.9335 49.9631 47.1902 49.4865 48.1652 48.5981C51.3719 45.8031 49.6602 40.1698 45.4352 39.6498C43.9185 30.5065 30.6802 33.9731 33.8219 42.6831" fill="#0071CF"/>
            <path d="M33.7569 42.6831C29.1852 43.0081 29.1852 49.6381 33.7569 49.9631H44.6119C45.9335 49.9631 47.1902 49.4865 48.1652 48.5981C51.3719 45.8031 49.6602 40.1698 45.4352 39.6498C43.9185 30.5065 30.6802 33.9731 33.8219 42.6831" fill="#0071CF"/>
            <path d="M47.6673 23.9847V33.2363C47.6673 33.9297 46.5623 34.233 46.0856 33.7347C45.0673 32.673 43.789 31.8713 42.3157 31.4163C38.914 30.3547 34.9923 31.3947 32.5223 33.9513C30.9623 35.6197 30.1173 37.6997 30.074 39.953C30.074 40.343 29.879 40.6897 29.5756 40.928C28.0156 42.163 27.0623 44.0913 27.0623 46.3013V46.323C27.0623 47.0163 26.564 47.6447 25.8706 47.6447H15.904C9.53398 47.6663 4.33398 42.4663 4.33398 36.0747V13.9097C4.33398 8.62301 8.62398 4.33301 13.9107 4.33301H18.9373C22.469 4.33301 23.574 5.48134 24.9823 7.36634L28.0156 11.3963C28.6873 12.2847 28.774 12.393 30.0306 12.393H36.0756C41.2323 12.393 45.609 15.773 47.104 20.453C47.429 21.428 47.6023 22.4247 47.6456 23.4863C47.6673 23.638 47.6673 23.833 47.6673 23.9847Z" fill="#0071CF"/>
          </svg>
          <h1 :style="!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime() ? 'justify-content: space-between;' : 'justify-content: center;'">
            {{ !isDocumentOpenValue ? 'Reabrir' : 'Concluir'}} processo
            <figure @click="isConfirmCompleteDocumentModal = false" v-if="!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g clip-path="url(#clip0_198_1747)">
                  <path d="M9.29404 7.89904L15.5 1.68604C15.6857 1.50039 15.79 1.24859 15.79 0.986043C15.79 0.723492 15.6857 0.471695 15.5 0.286043C15.3144 0.100392 15.0626 -0.00390625 14.8 -0.00390625C14.5375 -0.00390625 14.2857 0.100392 14.1 0.286043L7.90004 6.49904L1.68604 0.289043C1.50039 0.103392 1.24859 -0.00090625 0.986043 -0.00090625C0.723492 -0.00090625 0.471695 0.103392 0.286043 0.289043C0.100392 0.474695 -0.00390625 0.726492 -0.00390625 0.989043C-0.00390625 1.25159 0.100392 1.50339 0.286043 1.68904L6.50004 7.89904L0.289043 14.107C0.103392 14.2927 -0.00090625 14.5445 -0.00090625 14.807C-0.00090625 15.0696 0.103392 15.3214 0.289043 15.507C0.474695 15.6927 0.726492 15.797 0.989043 15.797C1.25159 15.797 1.50339 15.6927 1.68904 15.507L7.90004 9.29304L14.108 15.499C14.2937 15.6847 14.5455 15.789 14.808 15.789C15.0706 15.789 15.3224 15.6847 15.508 15.499C15.6937 15.3134 15.798 15.0616 15.798 14.799C15.798 14.5365 15.6937 14.2847 15.508 14.099L9.29404 7.89904Z"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_198_1747">
                  <rect width="15.794" height="15.793" fill="white"/>
                  </clipPath>
                  </defs>
              </svg>
            </figure>
          </h1>
          <h2 :style="!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime()? 'text-align: start;' : ''">Quer mesmo {{ !isDocumentOpenValue ? 'reabrir' : 'concluir'}} esse processo? <br>
            {{ isDocumentOpenValue ? 'Após conclusão só será possível novas interações se o documento for reaberto.' : '' }}  {{!(!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime()) && !isDocumentOpenValue ? 'Após conclusão já será possível ser feita novas interações.' : '' }}
          </h2>
          <div v-if="!isDocumentOpenValue && new Date(document.limit_date).getTime() < new Date().getTime()" class="dates">
            <MyDatePicker :min="{ day: new Date(document.limit_date).getDate(), month: new Date(document.limit_date).getMonth() + 1, year: new Date(document.limit_date).getFullYear() }" :setValue="(v) => updatePayload.limit_date = v" name="limit" />
          </div>
          <section>
            <button v-if="!isUpdatingDocument" @click="updateDocument(!isDocumentOpenValue ? DocumentStatusEnum.UNDER_REVIEW : DocumentStatusEnum.COMPLETED)">Sim</button>
            <SpinnerLoaderButton v-else :isButton='false' />
            <input @click="isConfirmCompleteDocumentModal = false;" type="button" value="Cancelar">
          </section>
        </div>
      </MyModal>

      <MyModal :isOpen="isFileVisualizerOpen" :class="'docView'" :toggleModal="(v: boolean) => isFileVisualizerOpen = v" :width="'80rem'" :height="'50rem'" :zIndex='10'>
        <iframe v-if="fileVisualizerSrc" :src="fileVisualizerSrc" width="100%" height="100%"></iframe>
        <SpinnerLoader v-else />
      </MyModal>
  </div>
  <SpinnerLoader v-else />
</template>

<script setup lang="ts">
import { ref, defineProps, onMounted, computed } from 'vue'
import MyModal from '@/components/modal/MyModal.vue'
import MyOptions from '@/components/options/MyOptions.vue'
import TextEditor from '@/components/input/TextEditor.vue'
import FileUploader from '@/components/input/FileUploader.vue'
import { useAxios } from '@/api/axios'
import { AxiosResponse } from 'axios'
import { DocumentModel, DocumentFileModel } from '@/models/DocumentModel'
import DocumentLogActionEnum, { documentLogActionTranslations } from '@/enums/DocumentLogActionEnum'
import { OrganizationModel } from '@/models/OrganizationModel'
import { UserModel } from '@/models/UserModel'
import MyDatePicker from '@/components/date/MyDatePicker.vue'
import DocumentStatusEnum, { documentStatusTranslations } from '@/enums/DocumentStatusEnum'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import { user } from '@/global/states/GlobalState'
import { toast } from 'vue3-toastify'
import MyTags from '@/components/tag/MyTags.vue'
import DocumentTagTypeEnum from '@/enums/DocumentTags/DocumentTagTypeEnum'
import useDocument from '@/hooks/documents/useDocument'

const axios = useAxios()
const document = ref<DocumentModel>()
const organizations = ref<OrganizationModel[]>()
const users = ref<UserModel[]>()
const limitDateValue = ref('')
const updatePayload = ref<Partial<{content?: string, limit_date?: string, files: File[], responsible: UserModel, organization: OrganizationModel}>>({ files: [] })
const isDatePickerModalOpen = ref(false)
const props = defineProps<{ id: number }>()
const documentViews = computed(() => document.value?.logs.filter(log => log.action == DocumentLogActionEnum.VIEW) ?? [])
const isUpdatingDocument = ref(false)
const isFileVisualizerOpen = ref(false)
const fileVisualizerSrc = ref('')
const mentions = computed<string[]>(() => (document.value?.tags.filter((tag) => tag.type === DocumentTagTypeEnum.MENTION).map((tag) => tag.title)) ?? [])
const keywords = computed<string[]>(() => (document.value?.tags.filter((tag) => tag.type === DocumentTagTypeEnum.KEYWORD).map((tag) => tag.title)) ?? [])

const { getAllowedUsersToTransfer, getAbilities, isDocumentOpen } = useDocument()
const isDocumentOpenValue = computed(() => document.value ? isDocumentOpen(document.value) : false)
const abilities = computed(() => getAbilities(user.value, document.value))
const usersToTransfer = computed(() => getAllowedUsersToTransfer(user.value, document.value, users.value, updatePayload.value.organization))

const isPayloadContentEmpty = computed(() => {
  const content = updatePayload.value.content ?? ''
  const strippedContent = content.replace(/<[^>]*>/g, '').trim()
  return strippedContent === ''
})

function getDocument (): void {
  axios.get(`api/documents/${props.id}`)
    .then((res: AxiosResponse<DocumentModel>) => {
      document.value = res.data
    })
}

function getOrganizations (): void {
  axios.get('/api/organizations')
    .then((res) => {
      organizations.value = res.data
    })
}

function getUsers (): void {
  axios.get('/api/users')
    .then((res) => {
      users.value = res.data
    })
}

function updateDocument (status?: DocumentStatusEnum): void {
  isUpdatingDocument.value = true

  if (status) {
    axios.patch(`/api/documents/${document.value!.id}`, { status: status, limit_date: updatePayload.value.limit_date })
      .then((res: AxiosResponse<{document: DocumentModel}>) => {
        document.value = res.data.document
      }).finally(() => {
        isConfirmCompleteDocumentModal.value = false
        isUpdatingDocument.value = false
        toast.success(`Documento ${status == DocumentStatusEnum.UNDER_REVIEW ? 'reaberto' : 'finalizado'}`)
      })
  } else {
    const payload = updatePayload.value.responsible ? { responsible_ids: [updatePayload.value.responsible.id], organization_ids: [updatePayload.value.organization!.id], ...updatePayload.value } : updatePayload.value
    if (isPayloadContentEmpty.value) {
      delete payload.content
    }
    axios.patch(`/api/documents/${document.value!.id}`, payload)
      .then((res: AxiosResponse<{document: DocumentModel}>) => {
        document.value = res.data.document
        updatePayload.value = { files: [] }
      }).finally(() => {
        isTransferDocumentModalOpen.value = false
        isUpdatingDocument.value = false
        toast.success(`${updatePayload.value.responsible ? 'Documento transferido' : 'Nova interação adicionada'}`)
      })
  }
}

async function visualizeFile (file: DocumentFileModel) {
  isFileVisualizerOpen.value = true
  fileVisualizerSrc.value = ''

  try {
    const response = await axios.get(`/api/documents/get-file-content/${file.id}`, { responseType: 'blob' })
    fileVisualizerSrc.value = URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
  } catch (error) {
    console.error('Erro ao buscar o documento:', error)
    return ''
  }
}

onMounted(() => {
  getDocument()
  getOrganizations()
  getUsers()
})

/* Alert modal variables. */
const isConfirmCompleteDocumentModal = ref<boolean>(false)
const isTransferDocumentModalOpen = ref<boolean>(false)

const formatDate = (date: string) => {
  const [year, mouth, day] = date.split('-')
  return `${day}/${mouth}/${year}`
}
</script>

<style lang="scss">
  .view-document{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 32px 0 0 0;
    box-sizing: border-box;
    .infos{
      width: 100%;
      margin: 0 0 5px 0;
      display: flex;
      column-gap: 20px;
      p{
        display: flex;
        flex-wrap: wrap;
        column-gap: 3px;
      }
      .interactions{
        width: 100%;
        max-width: 692px;
        display: flex;
        flex-direction: column;
        .document{
          width: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 24px;
          border: 1px solid #D3D3D3;
          position: relative;
          margin: 36px 0 0 0;
          .interaction-creator {
            margin: 0;
            display: inline-flex;
            position: absolute;
            top: -.63rem;
            left: 1.65rem;
            padding: 0.3125rem 0.375rem;
            border-radius: 0.5rem;
            background: var(--cinza-medio, #D3D3D3);
            color: var(--preto, #090909);
            font-family: Inter;
            font-size: 0.75rem;
            font-weight: 700;
          }
          &:nth-child(4){
            margin: 0;
            &::before{
              display: none;
            }
          }
          &::before{
            content: '';
            width: 2px;
            height: 36px;
            position: absolute;
            bottom: calc(100% + 1px);
            margin: 0 auto;
            background: #D3D3D3;
            position:absolute;
            left:50%;
            transform: translateX(-50%)
          }
          &__item{
            margin: 0 2rem;
            margin: 1rem;
            h1,h2,h3,h4,h5,h6{
              word-wrap: break-word !important;
              font-family: unset !important;
              font-weight: unset !important;
              color: unset !important;
              margin: unset !important
            }
            strong{
              font-size:unset !important;
              font-weight: bold !important;
            }
            h1 {
              font-size: 2em !important;
            }
            h2 {
              font-size: 1.5em !important;
            }
            h3 {
              font-size: 1.17em !important;
            }
            h4 {
              font-size: 1em !important;
            }
            h5 {
              font-size: 0.83em !important;
            }
            h6 {
              font-size: 0.67em !important;
            }
          }
          &__attachments{
            display: flex;
            flex-direction: column;
            padding: 17px 22px 26px;
            box-sizing: border-box;
            border-radius: 0px 0px 23px 23px;
            background: #F3F3F3;
            h1{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin: 0 0 8px 0;
            }
            div{
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0 0 10px 0;
              p{
                max-width: 80%;
                color: #737373;
                font-family: 'Inter', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration-line: underline;
                margin: 0;
                text-align: start;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
              }
              button{
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
                figure{
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0;
                  svg{
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    min-height: 20px;
                    stroke: #292F31;
                    transition: stroke .2s;
                  }
                }
                &::before{
                  content: 'Visualizar';
                  width: 85px;
                }
                &:hover{
                  figure{
                    svg{
                      stroke: $fluxoo-primary-color;
                    }
                  }
                }
              }
              a{
                button{
                  &::before{
                    content: 'Baixar';
                    width: 62px;
                  }
                }
              }
              &:last-child{
                margin: 0;
              }
              div{
                column-gap: 15px;
              }
            }
          }
        }
        .stats{
          display: flex;
          align-items: center;
          column-gap: 16px;
          margin: 0 0 24px 0;
          .tag{
            height: 40px;
            padding: 0 16px;
            border-radius: 30px;
            background: #F5F5F5;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &.under_review{
              background: #D5CEE3;
              color: #4F1AC0;
            }
            &.completed{
              background: #CDEFE6;
              color: #079F72;
            }
            &.overdue{
              background: #FFD7D7;
              color: #C70E0E;
            }
            &.extended{
              background: #FFF8D3;
              color: #C09809;
            }
            &.pending{
              background: #FDDCC5;
              color: #E87308;
            }
            &.exceptions{
              background: #C8DEFF;
              color: #0F2494;
            }
          }
        }
        h1{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-align: start;
          margin: 28px 0 0 0;
          strong{
            font-size: 14px;
            font-weight: 400;
          }
        }
        h2{
          color: #6F6F6F;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 0 13px 0;
          text-align: start;
          &.subtitle{
            color: #6F6F6F;
            font-family: 'Nunito', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: start;
            margin: 5px 0 40px 0;
          }
        }
        .text-editor{
          border-radius: 20px;
          border: 2px solid $fluxoo-primary-color;
          position: relative;
          margin: 36px 0 0 0;
          &__menu-bar{
            padding: calc(1rem + 10px) 2rem 1rem;
          }
          &::after{
            content: 'Nova interação';
            width: 105px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border-radius: 8px;
            background: $fluxoo-primary-color;
            position: absolute;
            top: -14px;
            left: 27px;
          }
          &::before{
            content: '';
            width: 2px;
            height: 36px;
            position: absolute;
            bottom: calc(100% + 2px);
            margin: 0 auto;
            background: $fluxoo-primary-color;
            position:absolute;
            left:50%;
            transform: translateX(-50%)
          }
        }
        .date-picker-label{
          position: relative;
          display: flex;
          align-items: center;
          input[type="text"]{
            width: 100%;
            height: 55px;
            border-radius: 14px;
            border: 1px solid #D3D3D3;
            color: $fluxoo-primary-color;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 15px 20px;
            box-sizing: border-box;
            transition: border-color .2s, color .2s, background .2s;
            &:focus{
              outline: none;
            }
            &::placeholder{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              transition: color .2s;
            }
          }
          svg{
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            stroke: #090909;
            position: absolute;
            right: 22px;
          }
        }
        .actions-doc{
          width: 100%;
          max-width: 1200px;
          position: sticky;
          bottom: 5px;
          margin: 28px 0 0 1px;
          &.reopen{
            display: none;
          }
          nav{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 32px;
            border-radius: 16px;
            background: #FFF;
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
            box-sizing: border-box;
            column-gap: 20px;
            button{
              column-gap: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              cursor: pointer;
              width: 100%;
              max-width: 464px;
              height: 60px;
              border-radius: 30px;
              background: $fluxoo-primary-color;
              color: #FFF;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              transition: background .2s;
              svg{
                stroke: #FFF;
              }
              &:hover{
                background: $fluxoo-primary-color-hover;
              }
            }
            input{
              border: none;
              cursor: pointer;
              &:first-child{
                width: 100%;
                max-width: 464px;
                height: 60px;
                border-radius: 30px;
                background: $fluxoo-primary-color;
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                transition: background .2s;
                &:hover{
                  background: $fluxoo-primary-color-hover;
                }
              }
              &:last-child{
                color: #737373;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                background: unset;
                transition: color .2s;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
            }
          }
        }
      }
      .historics{
        width: 100%;
        max-width: 488px;
        display: flex;
        flex-direction: column;
        align-items: start;
        row-gap: 10px;
        .actions{
          display: flex;
          column-gap: 16px;
          margin: 0 0 47px 0;
          button{
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 50px;
            background: #F5F5F5;
            padding: 0 20px;
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            column-gap: 10px;
            transition: background .2s, color .2s;
            cursor: pointer;
            svg{
              stroke: #090909;
              fill: #090909;
              transition: stroke .2s, fill .2s;
            }
            &:hover{
              background: $fluxoo-primary-color;
              color: #FFF;
              svg{
                stroke: #FFF;
                fill: #FFF;
              }
            }
          }
        }
        h1{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0 0 10px 0;
        }
        .info{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 24px;
          background: #F5F8F7;
          padding: 24px 20px;
          margin: 0 0 34px 0;
          box-sizing: border-box;
          p{
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            margin: 0 0 10px 0;
            text-align: start;
            strong{
              color: #6F6F6F;
              font-family: 'Inter', sans-serif;
              font-weight: 500;
            }
          }
          div{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 10px;
            row-gap: 10px;
            p{
              margin: 0;
              display: flex;
              column-gap: 3px;
            }
          }
        }
        .historic{
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          margin: 0 0 34px 0;
          .item{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 24px 20px;
            border-radius: 24px;
            border: 1px solid #F1F1F1;
            position: relative;
            box-sizing: border-box;
            span{
              width: 25px;
              min-width: 25px;
              height: 25px;
              min-height: 25px;
              border-radius: 50%;
              background: #D3D3D3;
              display: flex;
              align-items: center;
              justify-content: center;
              color:#737373;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              position: absolute;
              top: 24px;
              right: 20px;
            }
            p{
              display: unset;
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              margin: 0 0 10px 0;
              text-align: start;
              strong{
                color: #6F6F6F;
                font-family: 'Inter', sans-serif;
                font-weight: 500;
              }
              &:nth-child(2){
                max-width: calc(100% - 30px);
              }
            }
            div{
              width: 100%;
              display: flex;
              padding: 20px 15px;
              border-radius: 20px;
              background: #EFF5F4;
              box-sizing: border-box;
              margin: -2px 0 0 0;
              p{
                color: #084231;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0;
                text-align: start;
                strong{
                  font-weight: 700;
                  color: #084231;
                }
              }
            }
          }
        }
        .viewers{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          border-radius: 24px;
          border: 1px solid #F1F1F1;
          padding: 24px 20px;
          box-sizing: border-box;
          .viewer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 0 0 22px 0;
            margin: 0 0 20px 0;
            border-bottom: 1px solid #F1F1F1;
            p{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              margin: 0 0 10px 0;
              text-align: start;
              strong{
                color: #6F6F6F;
                font-family: 'Inter', sans-serif;
                font-weight: 500;
              }
              &:last-child{
                margin: 0;
              }
            }
            &:last-child{
              margin: 0;
              padding: 0;
              border: unset;
            }
          }
        }
      }
    }
    .modal{
      &.date-limit{
        .modal__content{
          padding: 15px 28px;
          box-sizing: border-box;
          overflow-y: auto;
          &::-webkit-scrollbar{
            width: 0;
          }
          button{
            display: flex;
            top: 26px;
            right: 28px;
          }
          .modal-date-title{
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: start;
            margin: 0 0 25px 0;
            h1{
              color: #090909;
              font-family: 'Nunito', sans-serif;
              font-size: 32px;
              font-weight: 800;
              text-align: start;
              margin: 0;
            }
            h2{
              color: #6F6F6F;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 2px;
              margin: 0;
            }
          }
          input[type='button']{
            width: 100%;
            min-height: 55px;
            border-radius: 30px;
            border: none;
            background: $fluxoo-primary-color;
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-weight: 600;
            margin: 8px 0 0 0;
            transition: background .2s;
            cursor: pointer;
            &:disabled{
              background: #F1F1F1 !important;
              color: #6F6F6F !important;
              cursor: not-allowed !important;
            }
            &:hover{
              background: $fluxoo-primary-color-hover;
            }
          }
        }
      }
    }
    .modal{
      &__content{
        margin: 15px 0 auto 0;
      }
      &.confirm-complete-document{
        &.picker{
          .modal__content{
            margin: 0;
            button{
              display: none;
            }
            div{
              align-items: start;
              padding: 19px 15px 24px;
              .close-modal{
                display: flex;
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
                position: absolute;
                top: 28px;
                right: 23px;
                cursor: pointer;
                figure{
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0;
                  svg{
                      width: 16px;
                      min-width: 16px;
                      height: 16px;
                      min-height: 16px;
                      fill: #090909;
                      transition: fill .2s;
                      &:hover{
                          fill: $fluxoo-primary-color;
                      }
                  }
                }
              }
              section{
                button{
                  display: flex;
                }
              }
            }
          }
        }
        .modal__content{
          button ~ div{
            overflow-y: auto;
            &::-webkit-scrollbar{
              width: 0;
            }
          }
          div{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 26px 15px 24px;
            box-sizing: border-box;
            h1{
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #090909;
              font-family: 'Nunito', sans-serif;
              font-size: 28px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              margin: 2px 0 7px 0;
              figure{
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1px 0 0 0;
                cursor: pointer;
                svg{
                  fill: #090909;
                  transition: fill .2s;
                  &:hover{
                    fill: $fluxoo-primary-color;
                  }
                }
              }
            }
            h2{
              color: #6F6F6F;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
            }
            section{
              width: 100%;
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: space-between;
              padding: 0;
              margin: auto 0 0 0;
              button{
                height: 30px;
                width: 80px;
                top: unset;
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: color .2s;
                cursor: pointer;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
              input{
                height: 60px;
                border-radius: 30px;
                background: #090909;
                color: #FFF;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border: none;
                width: 173px;
                cursor: pointer;
              }
            }
            .dates{
              padding: 15px 0;
              min-height: 330px;
              margin: 0 auto 10px;
              .date-picker{
                max-width: 290px;
                padding: 0;
                .select-date{
                  display: flex !important;
                  flex-direction: row !important;
                  padding: 0;
                  .mySelect{
                    &.open{
                      div{
                        display: flex;
                      }
                    }
                    div{
                      height: unset;
                      display: none;
                      padding: 5px 10px 5px 0;
                    }
                  }
                }
                .select-day{
                  padding: 0;
                  .date{
                    display: grid;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
        .close-modal{
          display: flex;
          top: 28px;
          right: 28px;
        }
      }
      &.transfer-document{
        padding: 15px;
        .modal__content{
          position: relative;
          padding: 32px 32px 0;
          box-sizing: border-box;
          overflow-y: auto;
          margin: 0;
          &::-webkit-scrollbar{
            width: 0;
          }
          .transfer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            h1{
              &:first-child{
                color: #090909;
                font-family: 'Nunito', sans-serif;
                font-size: 28px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                margin: 0;
              }
            }
            .option{
              .top{
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
            .text-editor{
              border: 1px solid #D3D3D3;
              margin: 0;
              &__menu-bar{
                flex-direction: row;
                box-sizing: border-box;
              }
              &--input{
                div{
                  box-sizing: border-box;
                }
              }
              &::before{
                content: unset;
              }
              &::after{
                content: unset;
              }
            }
            .file-document{
              width: 100%;
              margin: 0 0 40px 0;
            }
          }
          nav{
            width: 100%;
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 32px;
            border-radius: 16px;
            background: #FFF;
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.12);
            box-sizing: border-box;
            position: sticky;
            bottom: 5px;
            column-gap: 20px;
            margin: auto 0 0 1px;
            input{
              border: none;
              cursor: pointer;
              &:first-child{
                width: 100%;
                max-width: 464px;
                height: 60px;
                border-radius: 30px;
                background: $fluxoo-primary-color;
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                transition: background .2s;
                &:hover{
                  background: $fluxoo-primary-color-hover;
                }
              }
              &:last-child{
                color: #737373;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                background: unset;
                transition: color .2s;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
            }
          }
        }
        .close-modal{
          display: flex;
          top: 41px;
          right: 32px;
        }
      }
    }
  }

  @media(max-width: 1000px){
    .view-document{
      .infos{
        margin: 0 0 120px 0;
        row-gap: 28px;
        flex-direction: column;
        position: relative;
        .interactions{
          max-width: unset;
          .stats{
            &.reopen{
              padding: 0 0 0 141px !important;
            }
            &.close{
              padding: 0 0 0 300px !important;
            }
            &.block{
              padding: 0;
            }
          }
          .actions-doc{
            position: fixed;
            box-sizing: border-box;
            bottom: 4px;
            left: 0;
            z-index: 1;
            padding: 0 15px 0 81px;
          }
        }
        .historics{
          max-width: unset;
          .actions{
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }

  @media(max-width: 690px){
    .view-document{
      .infos{
        .interactions{
          .stats{
            margin: 0 0 12px 0;
            &.reopen{
              padding: 60px 0 0 0 !important;
            }
            &.close{
              padding: 60px 0 0 0 !important;
            }
            &.block{
              padding: 0;
            }
          }
        }
      }
    }
  }

  @media(max-width: 425px){
    #reopen{
      display: none;
    }
    .view-document{
      .infos{
        .interactions{
          .stats{
            margin: 0 0 12px 0;
            &.reopen{
              padding: 0 !important;
            }
          }
          .actions-doc{
            animation: enterToBottom ease 0.9s;
            width: 100vw;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 4;
            padding: 0;
            &.reopen{
              display: flex;
            }
            nav{
              border-radius: 20px 20px 0px 0px;
              background: #FFF;
              box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
              padding: 15px;
            }
          }
        }
      }
      .modal {
        padding: 0 15px;
        &__content{
          border-radius: 28px;
          max-width: unset !important;
          max-height: unset !important;
          bottom: unset;
          left: unset;
          right: unset;
          top: 0;
          opacity: 0;
          transition: all 0.2s ease-out;
          &.closed {
              opacity: 0;
              transform: translateY(-110%) !important;
          }
          &.open {
              opacity: 1;
              transform: translateY(0) !important;
          }
        }
        &.transfer-document{
          padding: 0;
          .modal__content{
            border-radius: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            nav{
              animation: enterToBottom ease 0.9s;
              width: 100vw;
              position: sticky;
              bottom: 0;
              border-radius: 20px 20px 0px 0px;
              background: #FFF;
              box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
              padding: 15px;
              box-sizing: border-box;
              z-index: 3;
              margin: 0;
            }
            .close-modal{
              display: flex;
              top: 38px;
              right: 15px;
            }
            .transfer{
              padding: 32px 15px 0;
              box-sizing: border-box;
              h1{
                &:first-child{
                  font-size: 24px;
                }
              }
            }
          }
        }
        &.confirm-complete-document{
          &.picker{
            padding: 10px;
            .modal__content{
              div{
                padding: 19px 10px 24px;
                .dates{
                  padding: 15px 0;
                  .date-picker{
                    .select-day{
                      .date{
                        gap: 2px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
