<template>
  <div id="user">
    <label @click="$router.push('/admin/home')" class="back">
        <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </figure>
        <p>Usuários</p>
    </label>
    <div class="header">
      <label class="search" for="search-user">
        <input autocomplete='off' type="text" placeholder="Digite sua busca" id="search-user" @input="filter.search_user = new RegExp(`.*${($event.target as HTMLInputElement).value}.*`, 'i')" @keydown.enter="getOrganizations" >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M17.5002 17.5002L13.881 13.881M13.881 13.881C14.5001 13.2619 14.9912 12.527 15.3262 11.7181C15.6612 10.9093 15.8337 10.0423 15.8337 9.16684C15.8337 8.29134 15.6612 7.42441 15.3262 6.61555C14.9912 5.80669 14.5001 5.07174 13.881 4.45267C13.2619 3.8336 12.527 3.34252 11.7181 3.00748C10.9093 2.67244 10.0423 2.5 9.16684 2.5C8.29134 2.5 7.42441 2.67244 6.61555 3.00748C5.80669 3.34252 5.07174 3.8336 4.45267 4.45267C3.2024 5.70295 2.5 7.39868 2.5 9.16684C2.5 10.935 3.2024 12.6307 4.45267 13.881C5.70295 15.1313 7.39868 15.8337 9.16684 15.8337C10.935 15.8337 12.6307 15.1313 13.881 13.881Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </label>
      <div class="filters">
        <button class="tooltip" @click="isGlobalFiltersModalOpen = true">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.9284 2.1001L5.99844 10.0001M5.39844 2.1001H18.5984C19.6984 2.1001 20.5984 3.0001 20.5984 4.1001V6.3001C20.5984 7.1001 20.0984 8.1001 19.5984 8.6001L15.2984 12.4001C14.6984 12.9001 14.2984 13.9001 14.2984 14.7001V19.0001C14.2984 19.6001 13.8984 20.4001 13.3984 20.7001L11.9984 21.6001C10.6984 22.4001 8.89844 21.5001 8.89844 19.9001V14.6001C8.89844 13.9001 8.49844 13.0001 8.09844 12.5001L4.29844 8.5001C3.79844 8.0001 3.39844 7.1001 3.39844 6.5001V4.2001C3.39844 3.0001 4.29844 2.1001 5.39844 2.1001Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
        </button>
      </div>
    </div>
    <div v-if="filteredOrganizations && filteredOrganizations?.length" class="results">
      <section v-for="organization in filteredOrganizations">
        <h1>{{ organization.name }}</h1>
        <div class="itens">
          <div v-for="user in organization.users?.filter((u) => filter.search_user?.test(u.name))" class="item">
            <h1>{{ user.name }} {{ user.last_name }}</h1>
            <h2>{{ user.roles[0].name }}</h2>
          </div>
        </div>
      </section>
    </div>
    <div class="no-options" v-else-if="filteredOrganizations && !filteredOrganizations?.length">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill='#171717' d="M14.9999 5.96651C14.9499 5.95817 14.8916 5.95817 14.8416 5.96651C13.6916 5.92484 12.7749 4.98317 12.7749 3.81651C12.7749 2.62484 13.7332 1.6665 14.9249 1.6665C16.1166 1.6665 17.0749 2.63317 17.0749 3.81651C17.0666 4.98317 16.1499 5.92484 14.9999 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path stroke='#171717' d="M14.1415 12.0333C15.2831 12.225 16.5415 12.025 17.4248 11.4333C18.5998 10.65 18.5998 9.36664 17.4248 8.58331C16.5331 7.99164 15.2581 7.79163 14.1165 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path fill='#171717' d="M4.9749 5.96651C5.0249 5.95817 5.08324 5.95817 5.13324 5.96651C6.28324 5.92484 7.1999 4.98317 7.1999 3.81651C7.1999 2.62484 6.24157 1.6665 5.0499 1.6665C3.85824 1.6665 2.8999 2.63317 2.8999 3.81651C2.90824 4.98317 3.8249 5.92484 4.9749 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path stroke='#171717' d="M5.83328 12.0333C4.69162 12.225 3.43328 12.025 2.54995 11.4333C1.37495 10.65 1.37495 9.36664 2.54995 8.58331C3.44162 7.99164 4.71662 7.79163 5.85828 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path fill='#171717' d="M10.0001 12.1916C9.95015 12.1833 9.89181 12.1833 9.84181 12.1916C8.69181 12.1499 7.77515 11.2083 7.77515 10.0416C7.77515 8.84994 8.73348 7.8916 9.92514 7.8916C11.1168 7.8916 12.0751 8.85827 12.0751 10.0416C12.0668 11.2083 11.1501 12.1583 10.0001 12.1916Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path stroke='#171717' d="M7.5751 14.8168C6.4001 15.6001 6.4001 16.8835 7.5751 17.6668C8.90843 18.5585 11.0918 18.5585 12.4251 17.6668C13.6001 16.8835 13.6001 15.6001 12.4251 14.8168C11.1001 13.9335 8.90843 13.9335 7.5751 14.8168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
      <h1>Nenhum usuário foi encontrado</h1>
    </div>
    <SpinnerLoader v-else />
    <nav :class="{active: isMobileOptionsFilterOpen}" @click="toggleOverflow()" class="action-filters">
      <button @click.stop @click="isGlobalFiltersModalOpen = true; toggleOverflow();">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.9284 2.1001L5.99844 10.0001M5.39844 2.1001H18.5984C19.6984 2.1001 20.5984 3.0001 20.5984 4.1001V6.3001C20.5984 7.1001 20.0984 8.1001 19.5984 8.6001L15.2984 12.4001C14.6984 12.9001 14.2984 13.9001 14.2984 14.7001V19.0001C14.2984 19.6001 13.8984 20.4001 13.3984 20.7001L11.9984 21.6001C10.6984 22.4001 8.89844 21.5001 8.89844 19.9001V14.6001C8.89844 13.9001 8.49844 13.0001 8.09844 12.5001L4.29844 8.5001C3.79844 8.0001 3.39844 7.1001 3.39844 6.5001V4.2001C3.39844 3.0001 4.29844 2.1001 5.39844 2.1001Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button>
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7 7L17 17M7 17L17 7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
    </nav>
    <MyModal :width="'554px'" :className="'global-filters'" :isOpen="isGlobalFiltersModalOpen" :toggleModal="(v: boolean) => isGlobalFiltersModalOpen = v" :zIndex="100">
      <h1>Filtros</h1>
      <div class="filters">
        <h2>Por setores</h2>
        <section>
          <label v-for="organization in organizations">
            <input type="radio" name="organization_id" id="id" :value="organization.id" v-model="filter.organization_id" >
            <span></span>
            <p>{{ organization.name }}</p>
          </label>
        </section>
      </div>
      <div class="action">
        <input type="button" value="Limpar" @click="isGlobalFiltersModalOpen = false; filter.organization_id = undefined">
      </div>
    </MyModal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import MyModal from '@/components/modal/MyModal.vue'
import { OrganizationModel } from '@/models/OrganizationModel'
import { AxiosResponse } from 'axios'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import { useAxios } from '@/api/axios'

const isMobileOptionsFilterOpen = ref<boolean>(false)
const isGlobalFiltersModalOpen = ref<boolean>(false)
const filter = ref<{ with: string[], organization_id?: number, search_user: RegExp }>({ with: ['users.roles'], search_user: new RegExp('.*.*', 'i') })
const organizations = ref<OrganizationModel[]>()
const filteredOrganizations = computed(() => organizations.value?.filter((org) => {
  const hasAnyUser = org.users?.filter((u) => filter.value.search_user.test(u.name)).length
  const isValid = org.id === filter.value.organization_id || !filter.value.organization_id

  return hasAnyUser && isValid
}))
const axios = useAxios()

const toggleOverflow = () => {
  isMobileOptionsFilterOpen.value = !isMobileOptionsFilterOpen.value
  document.body.style.overflow = isMobileOptionsFilterOpen.value ? 'hidden' : 'auto'
}

function getOrganizations () {
  organizations.value = undefined
  axios.get('/api/organizations', { params: filter.value })
    .then((res: AxiosResponse<OrganizationModel[]>) => {
      organizations.value = res.data
    })
}

onMounted(() => {
  getOrganizations()
})
</script>

<style lang="scss">
 #user{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  margin: 0 0 50px 0;
  animation: enterContent ease .4s;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    margin: 0 0 48px 0;
    .search{
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      input{
          width: 100%;
          height: 55px;
          border: none;
          border-radius: 16px;
          background: #F5F5F5;
          color: $fluxoo-primary-color;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 20px 56px 20px 20px;
          box-sizing: border-box;
          transition: background .2s;
          &:focus{
              outline: none;
              &::placeholder{
                color: transparent
              }
              & ~ svg{
                stroke: $fluxoo-primary-color;
              }
          }
          &::placeholder{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
          }
          &:read-only{
              cursor: default !important;
          }
      }
      svg{
          width: 20px;
          min-width: 20px;
          height: 20px;
          min-height: 20px;
          position: absolute;
          right: 20px;
          stroke: #090909;
          cursor: pointer;
      }
    }
    .filters{
      display: flex;
      align-items: center;
      column-gap: 15px;
      button{
        width: 50px;
        height: 50px;
        border-radius: 12px;
        border: 1px solid #D5D5D5;
        transition: border-color .2s;
        cursor: pointer;
        &:hover{
          border-color: $fluxoo-primary-color;
          outline: 1px solid $fluxoo-primary-color;
          svg{
            stroke: $fluxoo-primary-color;
          }
        }
        figure{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          svg{
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            stroke: #090909;
            transition: stroke .2s;
          }
        }
        &:nth-child(1)::before{
          content: 'Filtros Gerais';
          width: 110px;
          right: -2px;
        }
      }
    }
  }
  .results{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 56px;
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      row-gap: 10px;
      h1{
        color: #111716;
        font-family: 'Nunito', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      }
      .itens{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px 20px;
        .item{
          cursor: help;
          box-sizing: border-box;
          width: 100%;
          height: 86px;
          padding: 0 23px;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          row-gap: 4px;
          border-radius: 24px;
          border: 1px solid #D3D3D3;
          h1{
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
          }
          h2{
            color: #6F6F6F;
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
          }
        }
      }
    }
  }
  .action-filters{
    display: none;
  }
  .modal{
    &.global-filters{
      padding: 0;
      .modal__content{
        border-radius: 0;
        margin: 0 0 0 auto;
        padding: 32px 0 0;
        row-gap: 50px;
        overflow-y: auto;
        &::-webkit-scrollbar{
          width: 0;
        }
        &.closed{
          transform: translateY(0);
        }
        h1{
          color: #090909;
          font-family: 'Nunito', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
          text-align: start;
          padding: 0 32px;
        }
        button{
          display: flex;
          top: 37px;
          right: 32px;
        }
        .filters{
          display: flex;
          flex-direction: column;
          align-items: start;
          row-gap: 16px;
          padding: 0 32px;
          h2{
            color: #575757;
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 53px 0 0 0;
            &:first-child{
              margin: 0;
            }
          }
          section{
            display: flex;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 16px;
            &.no-responsibles{
              gap:0.5rem;
              align-items:center;
              p{
                margin:0 !important;
                color: #949494;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
              }
            }
            label{
              display: flex;
              align-items: center;
              column-gap: 8px;
              cursor: pointer;
              input{
                display: none;
                &:checked{
                  & ~ span{
                    border-color: $fluxoo-primary-color;
                    outline: 1px solid $fluxoo-primary-color;
                    &::before{
                      transform: scale(1.0);
                      opacity: 1;
                    }
                  }
                  & ~ p{
                    color: $fluxoo-primary-color;
                  }
                }
              }
              span{
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
                border-radius: 50%;
                border: 1px solid #D5D5D5;
                position: relative;
                transition: border-color .2s;
                &::before{
                  content: '';
                  width: 12px;
                  min-width: 12px;
                  height: 12px;
                  min-height: 12px;
                  border-radius: 50%;
                  background: $fluxoo-primary-color;
                  position: absolute;
                  top: 6px;
                  left: 6px;
                  transform: scale(0.0);
                  opacity: 0;
                  transition: transform .2s, opacity .1s;
                }
              }
              p{
                color: #949494;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                margin: 0;
                transition: color .2s;
              }
            }
          }
        }
        .action{
          width: 100%;
          max-width: 1200px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 32px;
          border-radius: 16px;
          background: #FFF;
          box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
          box-sizing: border-box;
          position: sticky;
          right: 0;
          bottom: 0;
          column-gap: 20px;
          margin: auto 0 0 0;
          input{
            border: none;
            cursor: pointer;
            &:last-child{
              color: #737373;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              background: unset;
              transition: color .2s;
              height: 60px;
              &:hover{
                color: $fluxoo-primary-color;
              }
            }
          }
        }
      }
    }
  }
}

@media(max-width: 1060px){
  #user{
    .results{
      section{
        .itens{
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
}

@media(max-width: 850px){
  #user{
    .results{
      section{
        .itens{
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

@media(max-width: 768px){
    #user{
      .header{
        .filters{
          display: none;
        }
      }
      .action-filters{
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        row-gap: 8px;
        position: fixed;
        bottom: calc(0dvh + 15px);
        right: 15px;
        z-index: 4;
        box-sizing: border-box;
        transition: opacity 0.3s;
        &.active{
          width: 100vw;
          height: 100dvh;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: end;
          row-gap: 8px;
          position: fixed;
          background: #292F3150;
          top: 0;
          bottom: unset;
          right: unset;
          left: 0;
          z-index: 4;
          padding: 15px;
          box-sizing: border-box;
          button{
            display: flex;
            align-items: center;
            justify-content: center;
            animation: enterContent ease .4s;
            &:last-child{
              figure{
                svg{
                  transform: rotate(0);
                }
              }
            }
          }
        }
        button{
          display: none;
          width: 45px;
          min-width: 45px;
          height: 45px;
          min-height: 45px;
          background: #FFF;
          border-radius: 50%;
          transition: background .2s;
          filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.15));
          cursor: pointer;
          &:hover{
            background: $fluxoo-primary-color;
            figure{
              svg{
                stroke: #FFF;
              }
            }
          }
          figure{
            widows: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            svg{
              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
              stroke: #090909;
              transition: stroke .2s;
            }
          }
          &:last-child{
            display: flex;
            align-items: center;
            justify-content: center;
            background: $fluxoo-primary-color;
            figure{
              svg{
                stroke: #FFF;
                margin: 0 0 0 -1px;
                transform: rotate(-405deg);
                transition: transform .2s, background .2s;
              }
            }
            &:hover{
              background: $fluxoo-primary-color-hover;
            }
          }
        }
      }
    }
}

@media(max-width: 600px){
  #user{
    .results{
      section{
        .itens{
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@media(max-width: 425px){
    #user{
      .action-filters{
        bottom: 15px;
        &.active{
          padding-bottom: 15px;
        }
      }
      .modal {
        padding: 0 15px;
        &__content{
          border-radius: 28px;
          max-width: unset !important;
          max-height: unset !important;
          bottom: unset;
          left: unset;
          right: unset;
          top: 0;
          opacity: 0;
          transition: all 0.2s ease-out;
          &.closed {
              opacity: 0;
              transform: translateY(-110%) !important;
          }
          &.open {
              opacity: 1;
              transform: translateY(0) !important;
          }
        }
      }
      .modal{
        &.date-filter{
          padding: 0;
          .modal__content{
            max-width: unset !important;
            padding: 32px 15px;
            button{
              right: 15px;
            }
          }
        }
        &.global-filters{
          .modal__content{
            h1{
              padding: 0 15px;
            }
            button{
              right: 25px;
            }
            .filters{
              padding: 0 15px;
            }
            .action{
              padding: 15px;
            }
          }
        }
      }
    }
  }
</style>
