<template>
  <div id="alerts">
    <label @click="$router.push('/admin/home')" class="back">
        <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </figure>
        <p>Alertas</p>
    </label>
    <label class="search" for="search-doc">
      <input
        autocomplete='off'
        type="text"
        placeholder="Digite sua busca"
        id="search-doc"
        name="search-doc"
        v-model="searchQueryInput"
        @keyup.enter="applySearch"
      >
      <svg @click="applySearch" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M17.5002 17.5002L13.881 13.881M13.881 13.881C14.5001 13.2619 14.9912 12.527 15.3262 11.7181C15.6612 10.9093 15.8337 10.0423 15.8337 9.16684C15.8337 8.29134 15.6612 7.42441 15.3262 6.61555C14.9912 5.80669 14.5001 5.07174 13.881 4.45267C13.2619 3.8336 12.527 3.34252 11.7181 3.00748C10.9093 2.67244 10.0423 2.5 9.16684 2.5C8.29134 2.5 7.42441 2.67244 6.61555 3.00748C5.80669 3.34252 5.07174 3.8336 4.45267 4.45267C3.2024 5.70295 2.5 7.39868 2.5 9.16684C2.5 10.935 3.2024 12.6307 4.45267 13.881C5.70295 15.1313 7.39868 15.8337 9.16684 15.8337C10.935 15.8337 12.6307 15.1313 13.881 13.881Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </label>
    <div class="alerts" v-if="filteredAlerts && filteredAlerts.length">
      <div class="alert" v-for="alert in filteredAlerts" :key="alert.id">
        <div>
          <h1>{{ alert.title }}</h1>
        </div>
        <p>{{ alert.description }}</p>
      </div>
    </div>
    <div class="no-options full" v-else-if="filteredAlerts && !filteredAlerts.length">
      <svg xmlns="http://www.w3.org/2000/svg" width="173" height="173" viewBox="0 0 173 173" fill="none">
        <path opacity="0.4" d="M151.952 61.8477V111.152C151.952 119.226 147.627 126.723 140.635 130.832L97.8176 155.556C90.8256 159.593 82.1754 159.593 75.1112 155.556L32.2937 130.832C25.3016 126.795 20.9766 119.298 20.9766 111.152V61.8477C20.9766 53.7744 25.3016 46.2774 32.2937 42.1687L75.1112 17.4441C82.1033 13.4075 90.7535 13.4075 97.8176 17.4441L140.635 42.1687C147.627 46.2774 151.952 53.7023 151.952 61.8477Z" fill="#292D32"/>
        <path d="M86.5 99.1146C83.5446 99.1146 81.0938 96.6638 81.0938 93.7084V55.8646C81.0938 52.9092 83.5446 50.4584 86.5 50.4584C89.4554 50.4584 91.9062 52.9092 91.9062 55.8646V93.7084C91.9062 96.6638 89.4554 99.1146 86.5 99.1146Z" fill="#292D32"/>
        <path d="M86.4993 124.344C85.5623 124.344 84.6251 124.127 83.7601 123.767C82.8231 123.406 82.1021 122.902 81.3813 122.253C80.7325 121.533 80.2282 120.74 79.7957 119.875C79.4352 119.01 79.291 118.072 79.291 117.135C79.291 115.261 80.0117 113.387 81.3813 112.017C82.1021 111.369 82.8231 110.864 83.7601 110.504C86.4272 109.35 89.5991 109.999 91.6174 112.017C92.2662 112.738 92.7705 113.459 93.1309 114.396C93.4913 115.261 93.7077 116.198 93.7077 117.135C93.7077 118.072 93.4913 119.01 93.1309 119.875C92.7705 120.74 92.2662 121.533 91.6174 122.253C90.2478 123.623 88.4456 124.344 86.4993 124.344Z" fill="#292D32"/>
      </svg>
      <h1>Não há alertas, por enquanto.</h1>
    </div>
    <SpinnerLoader v-else />
  </div>
</template>

<script setup lang="ts">
import { useAxios } from '@/api/axios'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import AlertModel from '@/models/AlertModel'
import { AxiosResponse } from 'axios'
import { computed, onMounted, ref } from 'vue'

const axios = useAxios()
const alerts = ref<AlertModel[]>([]);
const searchQuery = ref('');
const searchQueryInput = ref('');

const filteredAlerts = computed(() => {
  return alerts.value.filter(al =>
    al.title.toLowerCase().includes(searchQuery.value.toLowerCase()) || !searchQuery.value
  );
});

function getAlerts () {
  axios.get('/api/alerts').then((res: AxiosResponse<AlertModel[]>) => {
    alerts.value = res.data
  })
}

function applySearch() {
  searchQuery.value = searchQueryInput.value;
}

onMounted(() => {
  getAlerts()
})
</script>

<style lang="scss">

  #alerts{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    margin: 0 0 50px 0;
    animation: enterContent ease .4s;
    .search{
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 48px 0;
      input{
        width: 100%;
        height: 55px;
        border: none;
        border-radius: 16px;
        background: #F5F5F5;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 20px 56px 20px 20px;
        box-sizing: border-box;
        transition: background .2s;
        &:focus{
          outline: none;
          &::placeholder{
            color: transparent
          }
          & ~ svg{
            stroke: $fluxoo-primary-color;
          }
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &:read-only{
          cursor: default !important;
        }
      }
      svg{
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        position: absolute;
        right: 20px;
        stroke: #090909;
        cursor: pointer;
      }
    }
    .alerts{
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      margin: 0 0 30px 0;
      .alert{
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        background: #F1F1F1;
        padding: 1px;
        text-align: start;
        div{
          height: 90px;
          background: #FFF;
          border-radius: 24px 24px 0 0;
          padding: 20px 24px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          h1{
            width: 100%;
            color: #080808;
            font-family: 'Nunito', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 22px;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        p{
          color: #080808;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
          padding: 20px 24px 24px;
        }
      }
    }
  }

  @media(max-width: 1024px){
    #alerts{
      .alerts{
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media(max-width: 800px){
    #alerts{
      .alerts{
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media(max-width: 600px){
    #alerts{
      .alerts{
        grid-template-columns: 1fr;
      }
    }
  }
</style>
