<template>
  <label for="file-document" class="file-document">
    <input type="file" name="file-document" id="file-document" accept=".txt, .rtf, .doc, .docx, .odt, .pdf" @input="onFilesChange" multiple>
    <p>Clique aqui para adicionar um arquivo</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M27.332 13.586H23.4787C20.3187 13.586 17.7454 11.0127 17.7454 7.85268V3.99935C17.7454 3.26602 17.1454 2.66602 16.412 2.66602H10.7587C6.65203 2.66602 3.33203 5.33268 3.33203 10.0927V21.906C3.33203 26.666 6.65203 29.3327 10.7587 29.3327H21.2387C25.3454 29.3327 28.6654 26.666 28.6654 21.906V14.9193C28.6654 14.186 28.0654 13.586 27.332 13.586ZM15.372 18.0393C15.172 18.2393 14.9187 18.3327 14.6654 18.3327C14.412 18.3327 14.1587 18.2393 13.9587 18.0393L12.9987 17.0793V22.666C12.9987 23.2127 12.5454 23.666 11.9987 23.666C11.452 23.666 10.9987 23.2127 10.9987 22.666V17.0793L10.0387 18.0393C9.65203 18.426 9.01203 18.426 8.62536 18.0393C8.43939 17.8512 8.3351 17.5973 8.3351 17.3327C8.3351 17.0681 8.43939 16.8142 8.62536 16.626L11.292 13.9593C11.3854 13.8793 11.4787 13.8127 11.5854 13.7593C11.612 13.746 11.652 13.7327 11.6787 13.7193C11.7587 13.6927 11.8387 13.6793 11.932 13.666H12.0387C12.1454 13.666 12.252 13.6927 12.3587 13.7327H12.3854C12.492 13.7727 12.5987 13.8527 12.6787 13.9327C12.692 13.946 12.7054 13.946 12.7054 13.9593L15.372 16.626C15.7587 17.0127 15.7587 17.6527 15.372 18.0393Z"/>
    <path d="M23.2409 11.7463C24.5076 11.7597 26.2676 11.7597 27.7742 11.7597C28.5342 11.7597 28.9342 10.8663 28.4009 10.333C26.4809 8.39966 23.0409 4.91967 21.0676 2.94633C20.5209 2.39967 19.5742 2.773 19.5742 3.533V8.18633C19.5742 10.133 21.2276 11.7463 23.2409 11.7463Z"/>
    </svg>
    <div v-if="files.length" class="attachment">
      <h1>ANEXO</h1>
      <div class="item" v-for="(file, index) in files">
        <h2>{{ file.name }}</h2>
        <div>
          <button v-if="!file.name.includes('docx')" class="tooltip" @click="handleFileVisualization(file)">
            <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill="none" d="M12.9641 9.99993C12.9641 11.6499 11.6327 12.9833 9.98499 12.9833C8.33731 12.9833 7.00586 11.6499 7.00586 9.99993C7.00586 8.34993 8.33731 7.0166 9.98499 7.0166C11.6327 7.0166 12.9641 8.34993 12.9641 9.99993Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M9.98643 16.8913C12.924 16.8913 15.6618 15.1579 17.5674 12.1579C18.3164 10.9829 18.3164 9.00794 17.5674 7.83294C15.6618 4.83294 12.924 3.09961 9.98643 3.09961C7.04891 3.09961 4.3111 4.83294 2.40546 7.83294C1.65651 9.00794 1.65651 10.9829 2.40546 12.1579C4.3111 15.1579 7.04891 16.8913 9.98643 16.8913Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </figure>
          </button>
          <button class="tooltip" @click.stop="$event.preventDefault(); files.splice(index, 1); fileSrc = '', $emit('delete', file)">
            <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path fill="none" d="M18.4086 4.98307C15.6375 4.70807 12.8497 4.56641 10.0703 4.56641C8.42264 4.56641 6.77497 4.64974 5.12729 4.81641L3.42969 4.98307" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M8.00781 4.14199L8.19089 3.05033C8.32403 2.25866 8.42389 1.66699 9.83024 1.66699H12.0105C13.4169 1.66699 13.525 2.29199 13.6499 3.05866L13.8329 4.14199" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M16.6193 7.61621L16.0784 16.0079C15.9869 17.3162 15.912 18.3329 13.5903 18.3329H8.24781C5.92609 18.3329 5.85119 17.3162 5.75965 16.0079L5.21875 7.61621" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M9.5293 13.75H12.3004" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M8.83984 10.417H13.0006" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </figure>
          </button>
        </div>
      </div>
    </div>
  </label>
  <!-- Quando for reutilizar esse modal pfv usar a mesma classe -->
  <MyModal :isOpen="isFileVisualizerOpen" :class="'docView'" :toggleModal="(v: boolean) => isFileVisualizerOpen = v" :width="'80rem'" :height="'50rem'" :zIndex='10'>
    <iframe v-if="fileSrc && !isFileVisualizerLoading" :src="fileSrc" width="100%" height="100%"></iframe>
    <SpinnerLoader v-else-if="isFileVisualizerLoading" />
  </MyModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import MyModal from '../modal/MyModal.vue'
import SpinnerLoader from '../loader/SpinnerLoader.vue'

const props = defineProps<{ files: any[], pushFile:(file: any) => void, visualizeFile?: (file: any) => Promise<string> }>()
defineEmits(['delete'])
const isFileVisualizerOpen = ref<boolean>(false)
const fileSrc = ref<string>()
const isFileVisualizerLoading = ref(false)

async function handleFileVisualization (file: any) {
  if (props.visualizeFile && !(file instanceof File)) {
    isFileVisualizerOpen.value = true
    isFileVisualizerLoading.value = true
    fileSrc.value = await props.visualizeFile(file)
    isFileVisualizerLoading.value = false
  } else {
    visualizeFileWithFileReader(file)
  }
}

function visualizeFileWithFileReader (file: File) {
  const fileReader = new FileReader()

  fileReader.onload = () => {
    fileSrc.value = fileReader.result as string
    isFileVisualizerOpen.value = true
  }

  fileReader.readAsDataURL(file)
}

const onFilesChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target.files && target.files.length > 0) {
    for (let i = 0; i < target.files.length; i++) {
      props.pushFile(target.files[i])
    }
  }
  target.value = ''
}
</script>

<style lang="scss">

  .file-document{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0 0;
    border-radius: 16px;
    border: 2px dashed #090909;
    box-sizing: border-box;
    cursor: pointer;
    input{
      display: none;
    }
    p{
      color: #737373;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 12px 0;
      text-align: center;
    }
    svg{
      width: 32px;
      min-width: 32px;
      height: 32px;
      min-height: 32px;
      margin: 0 0 49px 0;
      fill: #737373;
    }
    .attachment{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 17px 22px 26px;
      box-sizing: border-box;
      border-radius: 0px 0px 14px 14px;
      background: #F5F5F5;
      row-gap: 20px;
      cursor: default;
      h1{
        color: #111716 !important;
        font-family: 'Inter', sans-serif !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
        margin: 0 0 -12px 0 !important;
      }
      .item{
        width: 100%;
        height: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;
        h2{
          color: #737373;
          font-family: 'Inter', sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
          margin: 0 !important;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          transition: color .2s;
        }
        div{
          display: flex;
          align-items: center;
          column-gap: 20px;
          button{
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            cursor: pointer;
            &:first-child{
              &::before {
                content: 'Visualizar';
                width: 84px;
              }
            }
            &:last-child{
              &::before {
                content: 'Deletar';
                width: 70px;
              }
            }
            &:hover{
              figure{
                svg{
                  stroke: $fluxoo-primary-color;
                }
              }
            }
            figure{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
              svg{
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
                stroke: #090909;
                margin: 0;
                transition: stroke .2s;
              }
            }
          }
        }
      }
    }
  }
</style>
